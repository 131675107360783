.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: var(--background-page);
    margin: 0 auto;
    padding: 2rem 0;
    gap: 2rem;
    position: relative;
}

.container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    border-top: 2px solid var(--text-color);
}

.infoContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--background-page);
    padding: 2rem;
}

.infoTitle {
    font-size: 2.5rem;
    text-align: center;
    color: var(--text-color);
    margin-bottom: 1rem;
}

.infoText {
    color: var(--text-color-muted);
    text-align: center;
    font-size: 1rem;
    margin-bottom: 1rem;
}

.contactForm {
    flex: 2;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    gap: 1.5rem;
    padding: 2rem;
    background-color: var(--background-page);
    border-radius: 8px;
    border: 1px solid var(--border-color);
}

.formGroup {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.labelWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--input-label-color);
    font-size: 0.875rem;
}

.label {
    margin-bottom: 0.5rem;
}

.required {
    color: var(--required-color);
    margin-left: 0.25rem;
}

.helperText {
    color: var(--text-color-muted);
    font-size: 0.75rem;
}

.inputField {
    background-color: var(--input-background);
    border: 1px solid var(--input-border);
    border-radius: 8px;
    padding: 0.75rem;
    color: var(--text-color);
    font-size: 1rem;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.inputField::placeholder {
    color: var(--input-placeholder);
}

.inputField:focus {
    border-color: var(--input-focus-border);
    outline: 2px solid var(--input-focus-outline);
    box-shadow: 0 0 0 3px var(--input-focus-outline);
}

.submitButton {
    background-color: var(--input-background);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    padding: 0.75rem 1.5rem;
    color: var(--text-color);
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.submitButton:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.submitButton:hover:enabled {
    background-color: var(--input-focus-border);
    border-color: var(--input-focus-border);
    color: #000;
}

.responseMessage {
    color: var(--text-color);
    text-align: center;
    margin-top: 1rem;
}

@media (max-width: 767px) {
    .container {
        flex-direction: column;
    }

    .infoContainer {
        margin-bottom: 2rem;
    }
}
