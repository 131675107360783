:root {
  --background-page: #000;
  --text-color: #fff;
  --text-color-muted: rgba(255, 255, 255, 0.5);
  --text-color-hover: rgba(255, 255, 255, 0.7);
  --border-color: rgba(255, 255, 255, 0.2);
  --input-background: #000;
  --input-border: rgba(255, 255, 255, 0.3);
  --input-placeholder: rgba(255, 255, 255, 0.5);
  --input-focus-border: #fff;
  --input-focus-outline: rgba(255, 255, 255, 0.5);
  --input-label-color: rgba(255, 255, 255, 0.7);
  --required-color: #ff6f61;
}

* {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  box-sizing: border-box;
}


