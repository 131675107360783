.homeContainer {
  background-color: var(--background-page);
  max-width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
  position: relative;
}

.homeTitle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--text-color);
  text-shadow: var(--text-shadow);
  font-size: 5rem;
  z-index: 10;
  pointer-events: none; 
  text-align: center;
}

