.yogaContainer {
    display: flex;
    flex-direction: column;
    padding: 5vw;
    background: var(--background-page);
}

.yogaSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8vw;
    flex-direction: row;
}

.leftSection {
    flex: 1;
    padding-right: 4vw;
}

.rightSection {
    flex: 1;
    padding-left: 4vw;
}

.yogaImage {
    width: 100%;
    height: auto;
}

.yogaText {
    font-size: 1.5vw;
    line-height: 2vw;
    color: var(--text-color);
}

.mantraBorder {
    font-size: 1.2vw;
    text-align: center;
    margin: 5vw 0;
    color: var(--text-color);
    white-space: pre-wrap;
}

@media (max-width: 768px) {
    .yogaSection {
        flex-direction: column;
    }

    .leftSection,
    .rightSection {
        padding: 0;
    }

    .yogaImage {
        margin-bottom: 5vw;
    }

    .yogaText {
        font-size: 4vw;
        line-height: 5vw;
    }

    .mantraBorder {
        font-size: 3vw;
        margin: 10vw 0;
    }
}
