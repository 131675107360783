.glassBox {
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: clamp(8px, 1vw, 12px); 
  backdrop-filter: blur(5px); 
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  padding: clamp(1.5rem, 4vw, 2.5rem); 
  text-align: center;
  color: var(--text-color-light);
  max-width: 90%;
  margin: 0 auto; 
  transition: box-shadow 0.3s ease, background 0.3s ease;
}

.glassTitle {
  font-size: clamp(1.5rem, 5vw, 2.2rem); 
  margin-bottom: clamp(0.5rem, 2vw, 0.75rem);
  color: rgba(255, 255, 255, 0.9);
}

.glassSubtitle {
  font-size: clamp(1rem, 3vw, 1.1rem); 
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.7);
}
