.actingContainer {
    width: 100vw;
    height: 100vh;
    padding: 0 15vw;
    background-color: var(--background-page);
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    overflow-y: auto;
  }

  .bannerImage {
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-bottom: 2rem;
    margin-top: 6em;
  }
  
  .bioContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 4rem;
  }
  
  .bio {
    font-size: 1.2rem;
    line-height: 1.6;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
    color: var(--text-color);
  }
  
  .creditsList {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
  }
  
  .creditItem {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  
  .role {
    font-weight: bold;
  }
  
  .production {
    font-style: italic;
  }
  
  .title {
    font-weight: bold;
  }
  
  .director {
    font-style: italic;
  }
  
  .imdbLink {
    font-size: 5.5rem;
    font-weight: bold;
    color: var(--text-color);
    text-decoration: none;
    margin-top: 3rem;
    transition: color 0.3s ease;
  }
  
  .imdbLink:hover {
    color: var(--text-color-hover);
  }
  
  /* Responsive breakpoints */
  
  @media (max-width: 1200px) {
    .actingContainer {
        padding: 0 10em;
    }
  }
  
  @media (max-width: 992px) {
    .actingContainer {
        padding: 0 7em;
    }
  }
  
  @media (max-width: 768px) {
    .actingContainer {
        padding: 0 4em;
    }
  }
  
  @media (max-width: 576px) {
    .actingContainer {
        padding: 0 2em;
    }
  }
  
  @media (max-width: 400px) {
    .actingContainer {
        padding: 0 1em;
    }
  }
  