.workScrollerContainer {
    background-color: var(--background-page);
    padding: 2rem;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
}

.projectLinksContainer {
    margin-bottom: 2rem;
    width: 100%;
    text-align: center;
}

.projectLinks {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.projectLinkItem {
    margin: 0.5rem 0;
}

.projectLink {
    text-decoration: none;
    color: var(--text-color);
    font-size: 1.5rem;
    transition: color 0.3s ease;
}

.projectLink:hover {
    color: var(--text-color);
}

.otherLinks {
    color: var(--text-color-hover);
}

.scrollContainer {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; 
}

.appRow {
    display: flex;
    list-style: none;
    padding: 1em 0;
    margin: 0;
}

.appCard {
    flex: 0 0 23em; 
    height: 39em;
    margin-right: 1.5em;
    border: 1px solid var(--text-color-hover);
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
}

.appCard:hover {
    transform: translateY(-10px); 
}

.appImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .projectLink {
        font-size: 1.2rem;
    }

    .appCard {
        flex: 0 0 15em;
        height: 25em;
    }
}
