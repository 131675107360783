.footer {
  border-top: 0.1rem solid var(--border-color);
  padding: 2rem 0;
  text-align: center;
  background-color: var(--background-page);
  color: var(--text-color);
}

.footerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  flex-wrap: wrap;
}

.instagramLink {
  flex-shrink: 0;
}

.instagramIcon {
  font-size: 2.4rem;
  color: var(--text-color);
}

.languageToggle {
  flex-shrink: 0;
  margin-left: auto;
  font-size: 1rem;
}

.copyright {
  font-size: 1.4rem;
  margin: 0;
  color: var(--text-color-muted);
  flex-grow: 1;
  text-align: center;
}

@media (max-width: 48rem) {
  .footerContainer {
    flex-direction: column;
    align-items: center;
    padding: 1rem 2rem;
  }

  .instagramLink {
    margin-bottom: 1rem;
  }

  .instagramIcon {
    font-size: 2rem;
  }

  .copyright {
    font-size: 1rem;
    text-align: center;
  }

  .languageToggle {
    margin-top: 1rem;
  }
}
