.aboutContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 15vw;
  background-color: var(--background-page);
  color: var(--text-color);
  min-height: 100vh; 
}


.aboutTitle {
  font-size: 3.3rem;
  margin-bottom: 1rem;
}

.aboutImage {
  width: 100%;
  max-width: 400px;
  height: auto;
  margin: 0 auto 1rem;
}

.aboutText {
  text-align: center;
  margin-bottom: 1rem;
  line-height: 1.6;
}

@media (max-width: 1200px) {
  .aboutContainer {
      padding: 0 10em;
  }
}

@media (max-width: 992px) {
  .aboutContainer {
      padding: 0 7em;
  }
}

@media (max-width: 768px) {
  .aboutContainer {
      padding: 0 4em;
  }
}

@media (max-width: 576px) {
  .aboutContainer {
      padding: 0 2em;
  }
}

@media (max-width: 400px) {
  .aboutContainer {
      padding: 0 1em;
  }
}