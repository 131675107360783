.discographyContainer {
  background-color: var(--background-page);
  color: var(--text-color);
  text-align: center;
  margin: 0 auto;
  padding: 2rem;
  box-sizing: border-box;
  max-width: 100%; /* Ensure it doesn't exceed the viewport */
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.discTitle {
  font-size: calc(2.5rem + 1vw); /* Responsive font size using calc */
  font-weight: bold;
  margin-bottom: 2rem;
}

.albumGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.album {
  width: calc(100% - 2rem); /* Ensure full width on smaller screens */
  max-width: 100%; /* Ensure the album covers fit within the viewport */
  aspect-ratio: 1 / 1;
  background-color: var(--background-page);
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid var(--text-color-hover);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.albumImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.albumTitle {
  margin-top: 1rem;
  font-size: 1rem;
  color: var(--text-color);
}

/* Responsive breakpoints */
@media (max-width: 768px) {
  .album {
    width: calc(100% - 2rem); 
  }

  .discTitle {
    font-size: calc(2rem + 1vw); /* Adjust font size for smaller screens */
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .album {
    width: calc(50% - 2rem); 
  }

  .discTitle {
    font-size: calc(2.5rem + 1vw);
  }
}

@media (min-width: 1025px) {
  .album {
    width: calc(33.33% - 2rem); /* Adjusted to fit more albums in larger viewports */
  }

  .discTitle {
    font-size: calc(3rem + 1vw);
  }
}
