.musicContainer {
  width: 100vw;
  min-height: 100vh;
  padding: 0 10vw; /* Adjusted padding */
  background-color: var(--background-page);
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  overflow-y: auto;
}

.bannerImage {
  width: 100%;
  max-height: 60vh;
  object-fit: cover;
  margin-bottom: 2rem;
  margin-top: 6em;
}

.bioContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4rem;
}

.bio {
  font-size: 1.2rem;
  line-height: 1.6;
  text-align: center;
  margin-bottom: 2rem;
}

.title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
  color: var(--text-color);
}

.linksContainer {
  display: flex;
  justify-content: center;
  gap: 4rem;
}

.link {
  font-size: 4rem;
  font-weight: bold;
  color: var(--text-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.link:hover {
  color: var(--text-color);
}

.otherLinks {
  color: var(--text-color-hover);
}

/* Responsive breakpoints */
@media (max-width: 1200px) {
  .musicContainer {
    padding: 0 10em;
  }
}

@media (max-width: 992px) {
  .musicContainer {
    padding: 0 7em;
  }
}

@media (max-width: 768px) {
  .musicContainer {
    padding: 0 4em;
  }
}

@media (max-width: 576px) {
  .musicContainer {
    padding: 0 5vw; /* Adjusted padding */
  }

  .bio {
    font-size: 1rem; /* Adjust font size for smaller screens */
  }

  .title {
    font-size: 1.5rem; /* Adjust title size for smaller screens */
  }

  .linksContainer {
    flex-direction: column;
    gap: 2rem;
  }

  .link {
    font-size: 3rem; 
  }
}

@media (max-width: 400px) {
  .musicContainer {
    padding: 0 2vw; /* Further adjustment for very small screens */
  }

  .bio {
    font-size: 0.9rem; /* Further adjustment for very small screens */
  }

  .title {
    font-size: 1.3rem; /* Further adjustment for very small screens */
  }

  .link {
    font-size: 2.5rem; /* Further adjustment for very small screens */
  }
}
