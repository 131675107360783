.pageBanner {
    position: relative;
    width: 100%;
    height: clamp(300px, 50vh, 600px); 
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: var(--background-page);
  }
  
  .shapesContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1; 
  }
  
  .overlayContainer {
    position: relative;
    width: clamp(300px, 80vw, 1000px); 
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  