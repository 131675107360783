.relaxWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  transition: background-color 0.3s ease;
}

.dawn {
  background: linear-gradient(to bottom, #F5F7FA, #FFEBCD, #FFDAB9); /* Subtle pastel sunrise */
}

.morning {
  background: linear-gradient(to bottom, #E0F7FA, #FFFAF0); /* Soft morning sky */
}

.noon {
  background: linear-gradient(to bottom, #87CEEB, #B0E0E6); /* Clear noon sky */
}

.afternoon {
  background: linear-gradient(to bottom, #ADD8E6, #87CEFA); /* Bright afternoon sky */
}

.evening {
  background: linear-gradient(to bottom, #FAD6A5, #FFA07A, #FFD700); /* Warm sunset colors */
}

.night {
  background: linear-gradient(to bottom, #2C3E50, #34495E, #2E4053); /* Deep, subtle night sky */
}

.relaxContainer {
  width: 100%;
  height: 100%;
}
