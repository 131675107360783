.toggleContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .toggleOption {
    margin: 0 0.5rem;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .active {
    color: var(--text-color);
  }
  
  .inactive {
    color: var(--text-color-hover);
  }
  
  .separator {
    color: var(--text-color-hover);
    margin: 0 0.2rem;
  }
  