.developmentPage {
  background-color: var(--background-page);
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5em;
}

.titleSection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2rem 0;
}

.developmentTitle {
  font-size: 3rem;
  margin: 0;
}

.shapesSection {
  position: relative;
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paragraphSection {
  max-width: 1000px;
  padding: 2rem;
  line-height: 1.8;
  font-size: 1.1rem;
  text-align: center;
  margin: 0 auto;
}

.workScrollerSection {
  width: 100%;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: var(--background-page);
}

@media (max-width: 768px) {
  .developmentTitle {
    font-size: 2.5rem;
  }

  .shapesSection {
    height: 400px;
  }

  .workScrollerSection {
    padding: 1rem 0;
  }

  .paragraphSection {
    padding: 1.5rem 1rem;
  }
}

@media (max-width: 480px) {
  .developmentTitle {
    font-size: 2rem;
  }

  .shapesSection {
    height: 300px;
  }

  .paragraphSection {
    font-size: 1rem;
    padding: 1rem;
  }
}
